import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import Drawer from 'components/UI/Drawer';
import { Checkbox } from 'components/UI/Checkbox';
import Select from 'components/UI/Select';

import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { useLoading } from 'utils/loading';
import {
  drawerMask as zIndexDrawerMask,
  modalAssistance as zIndexModalAssistance,
} from 'utils/zIndex';

import IconCloseBtn from 'images/close-btn.svg';

const Container = styled.div`
  width: 450px;
  height: 706px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

  @media (max-width: ${md}) {
    filter: none;
    width: 100%;
    height: auto;
    min-height: calc(100vh - 54px - 108px);
    padding: 0;
  }
`;

const OrderTitle = styled.div`
  background-color: rgba(95, 210, 218, 0.25);
  border: 1px solid #5fd2da;
  padding: 12px 16px;
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 12px;

  @media (max-width: ${md}) {
    padding: 16px;
    border: none;
    background-color: #eafeff;
    margin-bottom: 0;
  }
`;

const BtnClose = styled.img`
  position: absolute;
  top: 6px;
  right: 4px;
  cursor: pointer;
  z-index: ${zIndexModalAssistance + 1};

  @media (max-width: ${md}) {
    display: none;
  }
`;

const BottomBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 108px;
  background: #ffffff;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndexModalAssistance + 2};

  @media (max-width: ${md}) {
    position: fixed;
  }
`;

const Btn = styled.div`
  width: 343px;
  height: 60px;
  background-color: #5fd2da;
  border: 1px solid #5fd2da;
  border-radius: 40px;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DialogueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  height: 510px;
  overflow-y: scroll;

  @media (max-width: ${md}) {
    overflow-y: visible;
    padding: 16px;
    height: auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const DialogueC4 = styled.div`
  background-color: #f2f2f2;
  border-radius: 30px;
  padding: 6px 12px;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  width: fit-content;
`;

const DialogueUser = styled.div`
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 314px;
  align-self: flex-end;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  display: flex;
  flex-direction: column;
  gap: 16px 0;
`;

const DialogueUserDate = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  margin-top: 16px;
  text-align: right;
`;

const QuestionTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;
`;

const DialogueUserQuestion = styled.div`
  padding: 11px 23px 6px 10px;
`;

const DialogueUserReply = styled.div`
  border-top: 1px solid #dddddd;
  padding: 9px;
`;

const ReplyLabel = styled.div`
  padding: 2px 10px;
  background-color: #8185ca;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  margin-right: 4px;
`;

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px 0;
  margin-top: 6px;
`;

const Product = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  padding: 4px 0 4px 16px;
  font-size: 12px;
  font-weight: 400;
  color: #3b3516;
`;

const StyledDrawer = styled(Drawer)`
  .mask {
    z-index: ${zIndexModalAssistance};
  }

  .content-wrapper {
    width: 430px;
    height: 547px;
    background-color: #ffffff;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px 8px 150px;
    overflow-y: scroll;
    z-index: ${zIndexModalAssistance + 1};

    @media (max-width: ${md}) {
      width: 100%;
      height: 800px;
      top: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .content {
    height: auto;
    background-color: #ffffff;

    @media (max-width: ${md}) {
      margin-bottom: 180px;
    }
  }
`;

const QuestionForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`;

const QuestionFormBlock = styled.div``;

const QuestionFormBlockTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 8px;
`;

const QuestionFormMessageWrapper = styled.div`
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
`;

const QuestionFormMessageCheckList = styled.div`
  background-color: #f2f2f2;
  padding: 0 16px;
`;

const QuestionFormMessageCheck = styled.div`
  padding: 16px 0;

  &:not(:first-child) {
    border-top: 1px solid #ffffff;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  .checkbox-label {
    font-size: 14px;
    font-weight: 400;
    color: #3b3516;
  }
`;

const QuestionFormMessage = styled.textarea`
  resize: none;
  outline: none;
  border: none;
  width: 100%;
  height: 133px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
`;

const Assistance = (props) => {
  const { onClose, orderDetail, getOrderDetail } = props;
  const api = useAPI();
  const loading = useLoading();

  const [msg, setMsg] = useState([]);
  const [mainOption, setMainOption] = useState();
  const [subOption, setSubOption] = useState();
  const [subData, setSubData] = useState();
  const [submit, setSubmit] = useState({ category: {}, sub_category: {} });
  const [remarkInput, setRemarkInput] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [productList, setProductList] = useState([]);
  const [drawer, setDrawer] = useState(false);

  const openDrawer = () => {
    setDrawer(true);
    setRemarkInput('');
    setSelectedItems([]);
    handleMainCategory(mainOption[0]);
  };

  const closeDrawer = () => {
    setDrawer(false);
  };

  const addQuestion = () => {
    if (selectedItems.length === 0) {
      alert('請選擇商品');
      return;
    }

    if (remarkInput === '') {
      alert('請輸入問題內容');
      return;
    }

    sendHelp({
      sfcc_transaction_no: orderDetail.sfcc_transaction_no,
      userMsg: remarkInput,
      category: submit.category.label,
      sub_category: submit.sub_category.label,
      isAll: selectedItems.length === productList.length ? true : false,
      product: selectedItems,
    });
  };

  const handleMainCategory = (data) => {
    const subOption = subData[data.label];
    setSubOption(subOption);
    setSubmit((prevState) => ({
      ...prevState,
      category: data,
      sub_category: subOption[0],
    }));
  };

  const getOrderHelpCategory = () => {
    api
      .getOrderHelpCategory()
      .then((res) => {
        const mainArray = [];
        const subObject = {};
        for (const element of res) {
          mainArray.push({
            label: element.name,
            value: element.value,
          });
          const children = [];
          for (const child of element.childs) {
            children.push({
              label: child.name,
              value: child.value,
            });
          }
          subObject[element.name] = children;
        }
        setMainOption(mainArray);
        setSubData(subObject);
        //預設值
        setSubmit((prevState) => ({
          ...prevState,
          category: mainArray[0],
          sub_category: subObject[res[0].name][0],
        }));
        setSubOption(subObject[res[0].name]);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleItemSelection = (productNumber) => {
    const selectedIndex = selectedItems.indexOf(productNumber);

    let newSelectedItems = [...selectedItems];
    if (selectedIndex === -1) {
      newSelectedItems.push(productNumber);
    } else {
      newSelectedItems.splice(selectedIndex, 1);
    }

    setSelectedItems(newSelectedItems);
  };

  const sendHelp = (sendData) => {
    loading.show();
    api
      .sendOrderHelp(sendData)
      .then((res) => {
        if (!res) return;
        setDrawer(false);
        getOrderDetail(orderDetail.sfcc_transaction_no);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setMsg(orderDetail?.messages ?? []);
    setProductList(orderDetail?.products);
  }, [orderDetail]);

  useEffect(() => {
    getOrderHelpCategory();
    setSelectedItems([]);
  }, []);

  return (
    <Container>
      <BtnClose src={IconCloseBtn} onClick={drawer ? closeDrawer : onClose} />
      <OrderTitle>訂單編號：{orderDetail?.order_no}</OrderTitle>
      <DialogueWrapper>
        <DialogueC4>請問有任何疑問嗎？</DialogueC4>
        {msg?.map((item) => {
          return (
            <DialogueUser>
              <DialogueUserQuestion>
                <QuestionTitle>{item.category}</QuestionTitle>
                <QuestionTitle>{item.sub_category}</QuestionTitle>
                <ProductList>
                  {item.products?.map((product) => {
                    return <Product>{product.item_name}</Product>;
                  })}
                </ProductList>
                <div>{item.msg}</div>
                <DialogueUserDate>{item.date}</DialogueUserDate>
                <DialogueUserReply>
                  <ReplyLabel>回覆</ReplyLabel>
                  {item.reply === ''
                    ? '感謝您的提問，我們將盡快回覆。'
                    : item.reply}
                </DialogueUserReply>
              </DialogueUserQuestion>
            </DialogueUser>
          );
        })}
      </DialogueWrapper>
      <BottomBar>
        {drawer ? (
          <Btn onClick={addQuestion}>新增</Btn>
        ) : (
          <Btn onClick={openDrawer}>請輸入您要諮詢的問題</Btn>
        )}
      </BottomBar>
      <StyledDrawer
        open={drawer}
        onClose={closeDrawer}
        placement="bottom"
        backgroundFixed={isMobile}
      >
        <QuestionForm>
          <QuestionFormBlock>
            <QuestionFormBlockTitle>請選擇問題類別</QuestionFormBlockTitle>
            <Select
              isSearchable={false}
              onChange={(e) => handleMainCategory(e)}
              value={submit.category}
              options={mainOption}
            />
          </QuestionFormBlock>
          <QuestionFormBlock>
            <QuestionFormBlockTitle>請再細選您的問題</QuestionFormBlockTitle>
            <Select
              onChange={(e) => setSubmit({ ...submit, sub_category: e })}
              value={submit.sub_category}
              isSearchable={false}
              options={subOption}
            />
          </QuestionFormBlock>
          <QuestionFormBlock>
            <QuestionFormBlockTitle>訊息</QuestionFormBlockTitle>
            <QuestionFormMessageWrapper>
              <QuestionFormMessageCheckList>
                {productList?.map((product, idx) => {
                  return (
                    <QuestionFormMessageCheck key={idx}>
                      <StyledCheckbox
                        onChange={() =>
                          handleItemSelection(product.productnumber)
                        }
                        value={
                          selectedItems.indexOf(product.productnumber) !== -1
                        }
                      >
                        {product.item_name}
                      </StyledCheckbox>
                    </QuestionFormMessageCheck>
                  );
                })}
              </QuestionFormMessageCheckList>
              <QuestionFormMessage
                value={remarkInput}
                onChange={(e) => setRemarkInput(e.target.value)}
                placeholder="敘述文字請勿超過500中文字(包含標點符號)"
              />
            </QuestionFormMessageWrapper>
          </QuestionFormBlock>
        </QuestionForm>
      </StyledDrawer>
    </Container>
  );
};

export default Assistance;
