import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import HeaderWrapper from 'components/UI/Header/HeaderWrapper';
import Header from 'components/UI/Header';
import Assistance from 'components/Order/Assistance';
import { useLoading } from 'utils/loading';
import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';

const Container = styled.div`
  display: none;

  @media (max-width: ${md}) {
    display: block;
    margin: ${(props) => `${props.headerHeight}px`} auto 108px;
  }
`;

const StyledHeader = styled(Header)`
  .btn-cart-wrapper,
  .address-wrapper,
  .input-search-wrapper,
  .switch-delivery-wrapper {
    @media (max-width: ${md}) {
      display: none;
    }
  }
`;

const AssistancePage = (props) => {
  const { location } = props;
  const loading = useLoading();
  const api = useAPI();
  const transNo = location.pathname.split('/').pop();

  const [headerHeight, setHeaderHeight] = useState(0);
  const [orderDetail, setOrderDetail] = useState();

  const getOrderDetail = (transNo) => {
    loading.show();
    api
      .getOrderDetail(transNo)
      .then((res) => {
        setOrderDetail(res);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => loading.dismiss());
  };

  useEffect(() => {
    getOrderDetail(transNo);
  }, []);

  return (
    <Container headerHeight={headerHeight}>
      <HeaderWrapper getHeight={(height) => setHeaderHeight(height)}>
        <StyledHeader title="詢問訂單" />
      </HeaderWrapper>
      <Assistance orderDetail={orderDetail} getOrderDetail={getOrderDetail} />
    </Container>
  );
};

export default AssistancePage;
