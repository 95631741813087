import React from 'react';
import styled, { css } from 'styled-components';

import IconCheckMark from 'images/check-mark.svg';

const StyledLabel = styled.label`
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledCheckbox = styled.input`
  appearance: none;
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border-radius: 3px;
  cursor: pointer;

  ${(props) =>
    props.value &&
    css`
      background-color: #5fd2da;
      border: none;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background-image: url(${(props) => props.iconChecked});
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 100%;
      }
    `}
`;

const StyledText = styled.div`
  flex: 0 1 auto;
  margin-left: 8px;
`;

const Checkbox = (props) => {
  const { className, children, onChange, value, disabled } = props;

  return (
    <StyledLabel className={className}>
      <StyledCheckbox
        disabled={disabled}
        type="checkbox"
        iconChecked={IconCheckMark}
        value={value}
        onChange={onChange}
      />
      <StyledText className="checkbox-label">{children}</StyledText>
    </StyledLabel>
  );
};

export { Checkbox };
